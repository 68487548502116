<template>
  <div>
    <div ref="gRecaptcha"></div>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */
import { user, instant } from "@ivy-way/material";

export default {
  metaInfo() {},

  components: {},

  mixins: [],

  props: [],
  data() {
    return {};
  },
  computed: {
    user() {
      return user;
    },
    instant() {
      return instant;
    }
  },
  watch: {},

  mounted() {
    this.loaded();
    var script = document.createElement("script");
    script.src =
      "https://www.recaptcha.net/recaptcha/api.js?onload=ReCaptchaLoaded&render=explicit";
    script.async = true;
    script.defer = true;
    document.head.appendChild(script);
  },

  methods: {
    getToken(token) {
      console.log("token:" + token);
      this.$emit("getValidateCode", token);
    },
    loaded() {
      setTimeout(() => {
        if (
          typeof window.grecaptcha === "undefined" ||
          typeof window.grecaptcha.render === "undefined"
        ) {
          this.loaded();
        } else {
          window.grecaptcha.render(this.$refs.gRecaptcha, {
            sitekey: "6LfE5TAqAAAAAG1FnYC-aUhJBTXAqZLxN1HyGqkO",
            /**
             * res返回的是goole的验证情况，
             * 成功返回字符串
             * 失败不返回字符串
             * 所以根据字符串判断验证情况
             */
            callback: this.getToken
          });
        }
      }, 100);
    }
  }
};
</script>

<style scoped></style>
